import React from "react";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import jwtEncode from "jwt-encode";
import Header from "../../components/header";
import Discord from "../../components/connectSocial/discord";
import Twitter from "../../components/connectSocial/twitter";
import Ans from "./ans";
import Email from "./email";
import ConnectButton from "./connectButton";
import "./index.css";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { toggleChangeAvatarPanel } from "../../state/dialog";
import { profileviewed, setProfileName } from "../../api/profile";
import { updateProfileViewed } from "../../state/profile";
import { getBoringAvatar } from "../../util/boringAvatar";
import { fetchCredpoints, updateCredPointsLive } from "../../state/credpoints";
import { fetchRankings } from "../../state/leaderboard";
import ManageWallet from "./manageWallet";
import { useWallet } from "../../hook/useWallet";
import { SECRETKEY } from "../../constants";
import { WalletType } from "../../type/walletType";

const ProfilePage = () => {
  const { connected, address, network } = useWallet();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialized = useAppSelector((state) => state.profileState.initialized);
  const success = useAppSelector((state) => state.profileState.success);
  const profileViewed = useAppSelector(
    (state) => state.profileState.profileViewed
  );
  const profileImage = useAppSelector((state) => state.profileState.avatar);
  const walletType = useAppSelector((state) => state.globalState.walletType);

  useEffect(() => {
    const fetchData = async () => {
      if (initialized && success && address) {
        if (!profileViewed) {
          const token = jwtEncode({ wallet: address }, SECRETKEY);
          const res = await profileviewed(token);
          if (res.success) {
            // console.log(res, "        ", account.address)
            dispatch(updateProfileViewed(true));
          }
        }
      }
    };
    fetchData();
  }, [initialized, address]);

  useEffect(() => {
    const fetchDataTimeout = setTimeout(() => {
      if (!initialized)
        navigate("/");

    }, 8000);
    return () => {
      clearTimeout(fetchDataTimeout);
    };
  }, [initialized]);

  useEffect(() => {
    if (!connected) {
      navigate("/");
    }
  }, [connected, address])

  return (
    <div className="parallax font-[Inter]" id="cred-point">
      <Header />
      <div className="parallax__group">
        <div className="parallax__layer profile__effect1">
          <img src="/credpoints/effect1.png" alt="effect1" />
        </div>
        <div className="parallax__layer profile__effect2">
          <img src="/credpoints/effect2.png" alt="effect2" />
        </div>
        <div className="parallax__layer profile__effect3">
          <img src="/credpoints/effect3.png" alt="effect3" />
        </div>
      </div>
      <div className="relative w-full grid justify-center z-10 !bg-fixed">
        <div className="w-full md:w-[1000px] flex flex-col items-center mt-[172px] mb-10">
          <p className="text-2xl font-bold">MY PROFILE</p>
          <div className="flex justify-center mt-8 mb-4">
            <div className="relative border-[2px] border-primary-default rounded-full">
              {!initialized ? (
                <img
                  className="w-[100px] h-[100px] rounded-full z-50"
                  src="/default-image.png"
                  alt=""
                />
              ) : (
                <img
                  className="w-[100px] h-[100px] rounded-full z-50"
                  src={profileImage}
                  alt=""
                />
              )}
              <div
                onClick={() => dispatch(toggleChangeAvatarPanel(true))}
                className="absolute top-0 left-0 w-[100px] h-[100px] group hover:bg-[black] hover:opacity-70 rounded-full flex justify-center items-center cursor-pointer"
              >
                <p className="hidden font-bold text-white group-hover:block">
                  Change
                </p>
              </div>
            </div>
          </div>
          <ConnectButton />
        </div>
        <div className="min-w-[320px] md:w-[989px] flex flex-col items-center mt-[40px] mb:mt-[116px] mb-10 justify-center">
          <ManageWallet />
          {/* <Twitter isProfileModal={true} /> */}
          {/* <Discord isProfileModal={true} /> */}
          {walletType == WalletType.APTOS && <Ans />}
          <Email />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
