import React from "react";
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion";
import { toggleMintNFT, toggleIsSuccessMintNFT } from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useMintNFT } from "../../hook/useMintNFT";
import { updateMintState } from "../../state/profile";

const largeProps: ConfettiProps = {
  zIndex: 9999,
  force: 0.8,
  duration: 4000,
  particleCount: 500,
  width: 1920,
  colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

const MintNFTModal = () => {
  const dispatch = useAppDispatch();
  const mintNFT = useMintNFT();
  const isOpen = useAppSelector((state) => state.dialogState.bMintNFT);

  const isSuccessMint = useAppSelector(
    (state) => state.dialogState.bisSuccessMintNFT
  );
  const userName = useAppSelector((state) => state.profileState.userName);
  const avatar = useAppSelector((state) => state.profileState.avatar);

  const onClaim = async () => {
    try {
      const res = await mintNFT(userName, avatar);

      if (res.status == "Approved") {
        dispatch(toggleIsSuccessMintNFT(true));
        dispatch(updateMintState(true));
      } else {
        dispatch(toggleMintNFT(false))
      }
    } catch (error) {
      dispatch(toggleMintNFT((false)));
      console.error(error);
    }
  }

  const isSuccessMintNFT = (
    <div
      className={`${isOpen ? "block" : "hidden"
        } absolute z-20 inset-0 h-full flex justify-center items-center bg-black bg-opacity-90`}
    >
      <div>
        <div className="flex flex-col">
          <div className="items-center justify-center hidden mb-12 md:flex">
            <img src="/home/cred.svg" alt="cred" className="w-[158px] md:w-auto" />
          </div>
          <div className="flex flex-col items-center justify-center mb-10 text-center">
            <p className="text-[34px] font-bold leading-[133%] md:px-8 px-20 mt-10">Weocome to CRED!</p>
          </div>
        </div>
        <div className="w-[96%] ml-2 md:w-[560px] h-[190px] border-x border-t border-x-[#FFF] border-t-[#FFF] rounded-t-3xl bg-[url('../public/credpoints/mint_nft.svg')] bg-cover"></div>
        <div className="w-[96%] md:w-[560px] ml-2 border-x border-b border-x-[#FFF] border-b-[#FFF] rounded-b-3xl flex flex-col justify-center items-center bg-[#010101] backdrop-blur-[20px]">
          <div className="flex flex-row items-center justify-center gap-1">
            <p className="text-xl font-semibold leading-normal opacity-50">@</p>
            <p className="text-xl font-semibold leading-normal">{userName}</p>
            <img src="/credpoints/mintCheck.svg" alt="" />
          </div>
          <div className="flex flex-col items-center justify-center gap-2 p-6">
            {isSuccessMint && <ConfettiExplosion {...largeProps} />}
            <p className="text-[#FFF] text-2xl font-bold leading-normal text-center">
              Hooray!
            </p>
            <p className="text-[#FFF] text-sm font-medium pb-2 leading-[160%] text-center">
              You have minted your CRED Profile NFT and are officially verified!
              <br /> Enjoy CRED and all of its benefits!
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-4 mt-6 mb-10">
            <button
              className="py-4 px-8 bg-[#F5E27D] rounded-full text-base font-semibold leading-normal text-black"
              onClick={() => {
                dispatch(toggleMintNFT(false));
                dispatch(toggleIsSuccessMintNFT(false));
              }}
            >
              Continue to CRED
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {isSuccessMint ? (
        <div>{isSuccessMintNFT}</div>
      ) : (
        <div
          className={`${isOpen ? "block" : "hidden"
            } absolute z-20 inset-0 h-[1648px] flex justify-center items-center bg-[#121212] bg-opacity-90`}
        >
          <div className="absolute left-14 top-10 bg-[#222] rounded-full w-[116px] font-Inter text-[15px] font-semibold leading-[160%] hover:bg-[#333]">
            <button className="flex gap-2 px-6 py-4" onClick={() => dispatch(toggleMintNFT(false))}>
              <img src="/profile/arrowLeft.svg" alt="" />
              Back
            </button>
          </div>
          <div>
            <div className="flex flex-col">
              <div className="items-center justify-center hidden mb-12 md:flex">
                <img src="/home/cred.svg" alt="cred" className="w-[158px] md:w-auto" />
              </div>
              <div className="flex flex-col items-start justify-start mb-10 text-center">
                <p className="text-[34px] font-bold leading-[133%] px-8">Hey {userName}, mint your
                  <br />CRED NFT Profile and get all the perks!</p>
              </div>
              <div className="flex flex-col items-start justify-start gap-6 px-4 mb-10 md:justify-center md:items-center ">
                <div className="flex justify-center items-center gap-3 md:w-[70%]">
                  <div className="flex">
                    <img src="/home/reward.png" alt="" className="md:w-[88px] w-[130px]" />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[22px] font-bold leading-normal text-[#F5E27D]">Get CRED referral rewards!</p>
                    <p>Receive rewards when both you and your referred fren have minted the Profile NFT</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 md:w-[70%]">
                  <div className="flex">
                    <img src="/home/verify.png" alt="" className="md:w-[60px] w-[60px]" />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[22px] font-bold leading-normal text-[#FFFFFF]">Verifiable on-chain profile</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 md:w-[70%]">
                  <div className="flex items-center justify-center h-full">
                    <img src="/home/purple.png" alt="" className="md:w-[60px] w-[60px]" />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[22px] font-bold leading-normal text-[#FFFFFF]">TowneSquare Purple Badge</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 md:w-[70%]">
                  <div className="flex">
                    <img src="/home/premium.png" alt="" className="md:w-[60px] w-[60px]" />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[22px] font-bold leading-normal text-[#FFFFFF]">Premium features</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 md:w-[70%]">
                  <div className="flex">
                    <img src="/home/access.png" alt="" className="md:w-[60px] w-[90px]" />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[22px] font-bold leading-normal text-[#FFFFFF]">Access all social apps built on CRED</p>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3 md:w-[70%]">
                  <div className="flex">
                    <img src="/home/say.png" alt="" className="md:w-[60px] w-[60px]" />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[22px] font-bold leading-normal text-[#FFFFFF]">Say no to bots</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mb-20">
              <div className="w-[96%] relative ml-2 md:w-[560px] h-[190px] border-x border-t border-x-[#FFF] border-t-[#FFF] rounded-t-3xl bg-[url('../public/credpoints/mint_nft.svg')] bg-cover"></div>
              <div className="w-[96%] md:w-[560px] ml-2 border-x border-b border-x-[#FFF] border-b-[#FFF] rounded-b-3xl flex flex-col justify-center items-center bg-[#121212] backdrop-blur-[20px]">
                <div className="flex flex-row items-center justify-center gap-1">
                  <p className="text-xl font-semibold leading-normal opacity-50">@</p>
                  <p className="text-xl font-semibold leading-normal">{userName}</p>
                  <img src="/credpoints/mintCheck.svg" alt="" className="pl-1" />
                </div>
                <div className="flex flex-col items-center justify-center gap-2 p-6 mt-4">
                  <p className="text-[#FFF] text-2xl font-Grotesk font-bold leading-normal text-center">
                    Mint your CRED Profile NFT
                  </p>
                  <p className="text-[#FFF] text-sm font-Inter font-medium pb-2 leading-[160%] text-center items-center">
                    Verify yourself with an on-chain social profile
                  </p>
                </div>
                <div className="flex gap-1 mb-3">
                  <p className="text-sm font-semibold font-Inter leading-normal text-[#B9B9B9]">Mint:</p>
                  <p className="text-sm font-semibold font-Inter leading-normal text-[#F5E27D]">1 MOVE</p>
                  <img src="/credpoints/movement-logo.svg" alt="" />
                </div>
                <div className="flex flex-row items-center justify-center w-full gap-4 mt-4 mb-10">
                  <button
                    className="py-4 px-8 bg-[#F5E27D] rounded-full text-base font-semibold leading-normal text-black md:w-[35%] w-[80%]"
                    onClick={() => onClaim()}
                  >
                    Mint Profile NFT
                  </button>
                </div>
              </div>
              <button
                className="md:block hidden mt-16 md:w-[30%] py-4 px-8 border-2 border-[#F5E27D] rounded-full text-base font-semibold leading-normal text-[#F5E27D]"
                onClick={() => dispatch(toggleMintNFT(false))}
              >
                I'll do it later
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MintNFTModal;