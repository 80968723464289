import React from 'react';

const Carousel = () => {
  return (
    <div className='w-full px-2 md:px-16'>
      <div className='hidden md:block'>
        <div className="h-[300px] md:h-[300px] container mt-4 flex border border-gray-light-1 rounded-xl">
          <div className="min-w-full md:min-w-[50%] px-8 py-8 flex flex-col justify-center items-start">
            <p className='text-[23px] font-Grotesk font-bold leading-normal'>Renegades Liquify & Claim<br /> Quest</p>
            <p className="text-sm font-normal leading-[20px] mb-[30px] mt-2 text-[#B9B9B9]">This quest is all about liquifying and claim your <br />Renegades.</p>
            <button className='w-[150px] h-[43px] py-3 px-6 bg-primary-light hover:bg-primary-dark rounded-full text-black text-base font-semibold leading-normal text-center'>
              See details
            </button>
          </div>
          <div className="min-w-full md:min-w-[50%] flex flex-col justify-center items-start">
            <img src="/quests/image.png" alt="" className='w-[440px] h-[300px]' />
          </div>
        </div>
      </div>
      <div className='flex md:hidden'>
        <div className='container flex flex-col w-full mt-4 border border-gray-light-1 rounded-xl'>
          <div className='flex flex-col'>
            <img src="/quests/mobile_back.png" className='w-[100%]' alt="" />
          </div>
          <div className='flex flex-col w-full px-8 py-4'>
            <p className='text-[23px] font-Grotesk font-bold leading-normal mb-2'>Renegades Liquify & Claim Quest</p>
            <p className="text-sm font-normal leading-[20px] mb-[30px] text-[#B9B9B9]">This quest is all about liquifying and claim your Renegades.</p>
            <button className='w-[150px] mb-2 h-[43px] py-3 px-6 bg-primary-light hover:bg-primary-dark rounded-full text-black text-base font-semibold leading-normal text-center'>
              See details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Carousel;