import React from "react";
import { TwitterShareButton } from "react-share";
import { useAppSelector } from "../state/hooks";

const Share = () => {
  const myRanking = useAppSelector((state) => state.leaderboardState.myRank);
  const totalPoint = useAppSelector(
    (state) => state.credpointsState.totalPoint
  );
  const shareText = `Hey check out Cred! I’m ranked ${myRanking.toLocaleString()}th with ${totalPoint.toLocaleString()} CRED points!\nWhat’s your score?\nCheck it out on \n`;

  return (
    <TwitterShareButton url={`${window.location.origin}`} title={shareText}>
      <div className="min-w-[50px] px-3 py-3 md:p-4 flex justify-center gap-2 items-center border border-gray-light-1 hover:bg-gray-light-1/50 hober:border-gray-light-1 rounded-full cursor-pointer active:border-2 active:border-white active:bg-black">
        <img
          src="/share.svg"
          alt="share"
          className="w-6 h-6 -ml-2 md:ml-0 md:w-8 md:h-8"
        />
        <p className="text-sm md:hidden">Share</p>
      </div>
    </TwitterShareButton>
  );
};

export default Share;
