import React from "react";
import { useAppSelector } from "../../../../state/hooks";
import LoadingState from "../../../../components/loadingstate";
import { WalletType } from "../../../../type/walletType";

const RewardsQuests = () => {

  const completedQuests = useAppSelector((state) => state.credpointsState.aptTxsPercentage);
  const isLive = useAppSelector(state => state.credpointsState.isLive);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  return (
    <>
      {!isLive ? (
        <div className="container w-[260px] md:w-[320px] min-h-[172px] h-[172px] px-10 md:px-20 py-4 flex flex-col gap-4 justify-center items-center border border-gray-light-2 rounded-xl">
          <div className="flex w-full gap-4 h-9">
            <LoadingState className="w-1/3" />
            <LoadingState className="w-full" />
          </div>
          <LoadingState className="w-full h-9" />
        </div>
      ) : (
        <div className="container w-[260px] md:w-[320px] min-h-[172px]  p-6 flex flex-col justify-center items-center border border-gray-light-2 rounded-xl">
          {completedQuests !== undefined && completedQuests !== 0 ? (
            <>
              <div className="flex items-center justify-center gap-2">
                <img src="/quests/cup.svg" alt="swap" />
              </div>
              <p className="mt-4 text-sm font-medium leading-normal text-center md:text-base font-Grotesk">
                My rewards from Quests
              </p>
              <div className="flex items-center justify-center gap-2">
                <p className="text-[32px] font-Grotesk font-bold leading-normal">
                  25,500
                </p>
                <img src="/credpoints/cred.svg" className="w-[26px]" alt="" />
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center gap-2">
                <img src="/quests/cup.svg" alt="swap" />
              </div>
              <p className="mt-4 text-sm font-medium leading-normal text-center md:text-base font-Grotesk">
                My rewards from Quests
              </p>
              <div className="flex items-center justify-center gap-2">
                <p className="text-[32px] font-Grotesk font-bold leading-normal">
                  25,500
                </p>
                <img src="/credpoints/cred.svg" className="w-[26px]" alt="" />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RewardsQuests;