import React from "react";
import PointLogo from "./pointLogo";
import "./index.css";
import PrimaryButton from "../../../components/primaryButton";
import NftList from "./nftList";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { toggleEligibleNftModal, toggleNftList, toggleSuggestVerifyModal } from "../../../state/dialog";
import SuggestVerify from "../../../components/suggestVerify";
import { WalletType } from "../../../type/walletType";
import { RewardType } from "../../../type/rewardType";

const NftTrades = () => {
  const dispatch = useAppDispatch();
  const nfts = [];
  const visitorMode = useAppSelector((state) => state.globalState.visitorMode);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const rewardType = useAppSelector((state) => state.globalState.rewardType);

  return (
    <div className="w-full px-4">
      <div className="h-[332px] md:h-[450px] container mt-4 w-full flex border border-gray-light-2 rounded-xl">
        <div className="min-w-full md:min-w-[50%] p-6 flex flex-col justify-center items-center gap-2">
          <PointLogo />
          <p className="text-2xl font-bold">from NFT trades on&nbsp;</p>
          <img src="/credpoints/townesquare.svg" alt="" />
          <p className="mt-3 text-sm text-center text-gray-light-3">
            Get points when you trade NFTs on <br /> TowneSquare
          </p>
          {walletType == WalletType.APTOS && <p onClick={() => dispatch(toggleEligibleNftModal(true))} className="mb-4 text-sm font-normal cursor-pointer text-third-default">Show eligible NFTs</p>}
          {visitorMode ?
            <PrimaryButton
              className="w-full text-sm md:hidden"
              onClick={() => dispatch(toggleSuggestVerifyModal(true))}
            >
              Verify account and join Cred
            </PrimaryButton> :
            <PrimaryButton
              className="w-full h-12 px-8 py-4 text-sm text-black md:hidden"
              onClick={() => dispatch(toggleNftList(true))}
            >
              See eligible NFTs in your wallet
            </PrimaryButton>
          }
        </div>
        <div className="hidden md:block w-px border border-gray-light-1 border-opacity-[20%]" />
        {visitorMode ?
          <SuggestVerify /> :
          <div className="hidden md:block min-w-[50%]">
            {walletType == WalletType.MOVEMENT ? (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <img className="w-[133] justify-center mb-4" src={`${rewardType == RewardType.TOWNESQUARE && '/credpoints/verify_movement_townesquare.svg'}`} alt="effect1" />
                <div className="flex flex-row items-center justify-center gap-2">
                  <p className="text-[22px] font-bold leading-normal">TowneSquare is</p>
                  <button className="text-[22px] font-bold leading-normal text-[#F5E27D]">COMING SOON</button>
                </div>
                <p className="text-[22px] font-bold leading-normal mb-4">on Movement</p>
              </div>
            ) : (
              nfts.length > 0 ? (
                <NftList />
              ) : (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <img className="w-[133] justify-center mb-4" src={`${rewardType == RewardType.TOWNESQUARE && '/credpoints/aptos_verify.svg'}`} alt="effect1" />
                  <div className="flex flex-row items-center justify-center gap-2">
                    <p className="text-[22px] font-bold leading-normal">TowneSquare is</p>
                    <button className="text-[22px] font-bold leading-normal text-[#F5E27D]">COMING SOON</button>
                  </div>
                  <p className="text-[22px] font-bold leading-normal mb-4">on Aptos</p>
                </div>
              )
            )}

          </div>
        }
      </div>
    </div>
  );
};

export default NftTrades;
