import React from 'react';
import { QuestsType } from '../../../type/questsType';
import { useAppSelector } from '../../../state/hooks';
import ClaimQuest from './claimQuest';
const QuestActivities = () => {
  const currentQuest = useAppSelector((state) => state.globalState.questsType);
  return (
    <div className='w-full'>
      {QuestLists.map((quest, index) => {
        if (quest.status == currentQuest) {
          return (
            <div>
              <ClaimQuest data={quest} key={index} />
            </div>
          )
        }
      })}
    </div>
  );
};

export interface QuestActivityType {
  imgSrc: string;
  title: string;
  content: string;
  days: string;
  reward: number;
  status: QuestsType
}

const QuestLists: QuestActivityType[] = [
  {
    imgSrc: "/quests/claimquest/avatar.png",
    title: "Renegades Liquify & Claim Quest",
    content: "This quest is all about liquifying and claim your Renegades. Every time you liquify or claim you get a chance of getting a higher rarity NFT! Liquify at least 5 Renegades in order to...",
    days: "4 days",
    reward: 5000,
    status: QuestsType.ACTIVE,
  },
  {
    imgSrc: "/quests/claimquest/slothball.png",
    title: "CoolSloths - Mint 10 Slothballs",
    content: "This quest is all about liquifying and claim your Renegades. Every time you liquify or claim you get a chance of getting a higher rarity NFT! Liquify at least 5 Renegades in order to...",
    days: "5 days",
    reward: 2000,
    status: QuestsType.ACTIVE,
  },
  {
    imgSrc: "/quests/claimquest/liquiswap.png",
    title: "Make 10 swaps on Liquiswap",
    content: "This quest is all about liquifying and claim your Renegades. Every time you liquify or claim you get a chance of getting a higher rarity NFT! Liquify at least 5 Renegades in order to...",
    days: "22h 04m",
    reward: 500,
    status: QuestsType.ACTIVE,
  },
  {
    imgSrc: "/quests/claimquest/liquiswap.png",
    title: "Make 10 swaps on Liquiswap",
    content: "This quest is all about liquifying and claim your Renegades. Every time you liquify or claim you get a chance of getting a higher rarity NFT! Liquify at least 5 Renegades in order to...",
    days: "22h 04m",
    reward: 500,
    status: QuestsType.COMINGSOON,
  },
  {
    imgSrc: "/quests/claimquest/liquiswap.png",
    title: "Make 10 swaps on Liquiswap",
    content: "This quest is all about liquifying and claim your Renegades. Every time you liquify or claim you get a chance of getting a higher rarity NFT! Liquify at least 5 Renegades in order to...",
    days: "",
    reward: 500,
    status: QuestsType.ENDED,
  }
]

export default QuestActivities;