import React from "react";
import Slider from "react-slick";
import MyQuests from "./myQuests";
import RewardsQuests from "./rewardsQuests";

const Cards = () => {
  const settings = {
    infinite: false,
    slidesToShow: 1.4,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.3,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  };
  return (
    <>
      <div className="items-center justify-center hidden w-full gap-2 px-4 mt-16 md:flex">
        <MyQuests />
        <RewardsQuests />
      </div>
      <div className="w-full mt-4 md:mt-16 credpoints md:hidden">
        <Slider {...settings}>
          <MyQuests />
          <RewardsQuests />
        </Slider>
      </div>
    </>
  );
};
export default Cards;