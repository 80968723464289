import React from "react";
import { useAppSelector } from "../../../state/hooks";
import ConnectionItem from "./connectionItem";

const ConnectionList = () => {
  const lastUserList = useAppSelector(
    (state) => state.leaderboardState.lastUserList
  );
  const currentTap = useAppSelector(
    (state) => state.leaderboardState.leaderboardTapIndex
  );
  const sortedLastUserList = [...lastUserList].sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
  return (
    <div className={`${currentTap == 1 ? 'flex' : 'hidden md:flex'} mt-8 md:mt-20 md:py-5 md:w-[45%] w-full flex-col items-center`}>
      <p className="text-center text-[20px] md:text-[32px] font-bold leading-normal">CRED newcomers</p>
      <div className="w-8 h-1 mt-2 border bg-secondary-default border-secondary-default" />
      <div
        className="container mt-8 w-[95%] border border-gray-light-1 md:border-gray-light-4 rounded-2xl"
        style={{ backdropFilter: 'blur(20px)' }}
      >
        <div className="flex flex-col w-full h-full">
          {sortedLastUserList.map((user, index) => (
            <ConnectionItem data={user} index={index} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConnectionList;
