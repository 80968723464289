import React from "react";
import { useAppSelector } from "../../state/hooks";
const Logo = () => {
  const step = useAppSelector((state) => state.globalState.step);
  return (
    <div className="flex flex-col items-center">
      <img src="/home/cred.svg" alt="cred" className="w-[158px] md:w-auto" />
      {step == 0 && (
        <img src="/home/logo_season01_big.svg" alt="cred" className="w-[158px] md:w-[234px] mt-[32px] md:mt-11" />
      )}
    </div>
  )
}

export default Logo;