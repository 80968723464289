import React from "react";

import { useEffect, useState } from "react";
import { toggleChangeUsername, toggleMintNFT } from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import jwtEncode from 'jwt-encode';
import { claimUsername } from "../../api/profile";
import { useWallet } from "../../hook/useWallet";
import { SECRETKEY } from "../../constants";
import { updateUserName } from "../../state/profile";
import { isValidInput } from "../../util/isValidInput";
import { WalletType } from "../../type/walletType";
import { updateStep } from "../../state/global";
import { initialize } from "@solflare-wallet/wallet-adapter";
import './claimLoader.css'
enum ClaimState {
  Initial,
  Loading,
  Success,
  Error,
}


const ChangeUsernameModal = () => {
  const isOpen = useAppSelector((state) => state.dialogState.bChangeUsername);
  const dispatch = useAppDispatch();

  const currentUserName = useAppSelector((state) => state.profileState.userName);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const [userName, setUserName] = useState('');
  const [claimStatus, setClaimStatus] = useState('');
  const { address } = useWallet();
  const [claimStateStep, setClaimStateStep] = useState(ClaimState.Initial);
  const avatar = useAppSelector((state) => state.profileState.avatar);

  const handleChangeUserName = (e: any) => {
    setClaimStateStep(ClaimState.Initial);
    setUserName(e.target.value);
  }

  const handleClaimUserNameClick = async () => {
    if (userName.length < 4) {
      setClaimStateStep(ClaimState.Error);
      setClaimStatus("Username must be longer than 4 characters");
      return;
    } else if (userName.length > 15) {
      setClaimStateStep(ClaimState.Error);
      setClaimStatus("Username can be max. 15 characters long");
      return;
    } else if (userName === currentUserName) {
      setClaimStateStep(ClaimState.Error);
      setClaimStatus("Username is already taken");
      return;
    } else if (!isValidInput(userName)) {
      setClaimStateStep(ClaimState.Error);
      setClaimStatus("Use only letters and numbers or underscore");
      return;
    }
    try {
      setClaimStateStep(ClaimState.Loading);
      const token = jwtEncode({ wallet: address }, SECRETKEY);
      const isInit = true;
      const response = await claimUsername(userName, token, isInit, avatar);
      if (response.success === true) {
        setClaimStateStep(ClaimState.Success);
        dispatch(toggleChangeUsername(false));
        dispatch(updateUserName(userName));
        const mintProfileTimeout = setTimeout(() => {
          if (walletType == WalletType.MOVEMENT) {
            dispatch(updateStep(3));
          } else {
            dispatch(updateStep(2));
          }
        }, 2000);
        return () => {
          clearTimeout(mintProfileTimeout);
        };
      } else {
        setClaimStateStep(ClaimState.Error);
        setClaimStatus(response?.error);
      }
    } catch (err) {
      setClaimStateStep(ClaimState.Error);
      console.error(err);
    }
  }
  return (
    <div
      className={`${isOpen ? 'flex' : 'hidden'
        }md:h-screen flex justify-center items-center`}
    >
      <div>
        <div className="w-[105%] md:w-[560px] h-[190px] border-x border-t border-x-[#FFF] border-t-[#FFF] rounded-t-3xl bg-[url('../public/credpoints/cred_back_2x.png')] bg-center bg-cover">
        </div>
        <div className="w-[105%] md:w-[560px] border-x border-b border-x-[#FFF] border-b-[#FFF] rounded-b-3xl p-6 flex flex-col justify-center items-center bg-[#121212] backdrop-blur-[20px]">
          <div className="flex flex-col items-center justify-center w-full gap-2 px-6">
            <div className="flex flex-col items-center justify-center w-full gap-8 mt-2 md:mt-6">
              <div className="flex flex-col items-center justify-center w-full">
                <div className="flex items-center w-full bg-black border rounded-full">
                  <span className="ml-8 opacity-50">@</span>
                  <input type="text" value={userName} placeholder="Input username" onChange={handleChangeUserName} className={`md:w-[80%] w-[70%] font-Inter px-2 py-3 bg-black border-none outline-none ${claimStatus ? 'border-[#E12E2E]' : ''}`} />
                </div>
                {claimStateStep === 3  && (
                  <div className={`flex items-start justify-start w-full ${claimStatus === '' ? 'hidden' : ''}`}>
                    <p className={`text-[15px] text-[#FF4471] font-Inter font-medium leading-normal mt-2`}>{claimStatus}</p>
                  </div>
                )}
                <div className="flex w-full pt-2 text-start">
                  <img src="/credpoints/icon-info.svg" className="w-[22px] h-[22px] mr-2" alt="cred" />
                  <span className="font-Inter text-[#B9B9B9] text-sm font-medium leading-[160%] w-full">
                    It can only contain letters, numbers and underscore.
                  </span>
                </div>
              </div>
              <button onClick={handleClaimUserNameClick} disabled={claimStateStep === 3 ? true : false} className={`${claimStateStep == 2 ? 'bg-third-default' : 'bg-primary-default'} ${claimStateStep === 3 ? 'bg-primary-default/30' : 'bg-primary-default'} text-center flex w-[200px] h-[49px] py-3 px-8 rounded-[200px] text-black font-bold text-[16px]`}
              >
                {claimStateStep === 0 && (<span className="w-full text-center">Claim username</span>)}
                {claimStateStep === 1 && (<div className="w-full ml-12 claimLoader"></div>)}
                {claimStateStep === 2 && (<img src="/credpoints/check.svg" className="ml-12 -mt-1" alt="check" />)}
                {claimStateStep === 3 && (<span className="w-full text-center">Claim username</span>)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeUsernameModal;