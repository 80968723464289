import React from "react";
import PointLogo from "./pointLogo";
import "./index.css";
import PrimaryButton from "../../../components/primaryButton";
import ReferralList from "./tokenList";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { toggleEligibleTokenModal, toggleReferral, toggleSuggestVerifyModal, toggleTokenModal } from "../../../state/dialog";
import SuggestVerify from "../../../components/suggestVerify";
import TokenList from "./tokenList";
import { WalletType } from "../../../type/walletType";

const TokenBoard = () => {
  const dispatch = useAppDispatch();
  const referralList = useAppSelector(
    (state) => state.credpointsState.referralList
  );
  const visitorMode = useAppSelector(
    (state) => state.globalState.visitorMode
  );
  const walletType = useAppSelector((state) => state.globalState.walletType);

  return (
    <div className="w-full px-4">
      <div className="hidden md:block">
        <div className={`${walletType == WalletType.MONAD ? 'h-[482px]' : 'h-[332px]'} md:h-[450px] container mt-4 flex border border-gray-light-2 rounded-xl`}>
          <div className="min-w-full md:min-w-[50%] p-6 flex flex-col justify-center items-center gap-4">
            <PointLogo />
            <p className="text-2xl font-bold text-center">from holding or staking tokens</p>
            <div className="flex flex-col items-center w-full">
              <p className="text-sm text-center text-gray-light-3 w-[90%]">
                Get daily points by holding or staking a certain amount of eligible tokens
              </p>
              {walletType == WalletType.APTOS && <p onClick={() => dispatch(toggleEligibleTokenModal(true))} className="mt-2 text-sm font-normal cursor-pointer text-third-default">Show eligible tokens</p>}
            </div>
            {visitorMode ?
              <PrimaryButton
                className="w-full text-sm md:hidden"
                onClick={() => dispatch(toggleSuggestVerifyModal(true))}
              >
                Verify account and join Cred
              </PrimaryButton> :
              <PrimaryButton
                className="w-full py-4 text-sm md:hidden"
                onClick={() => dispatch(toggleTokenModal(true))}
              >
                Last 10 rewards
              </PrimaryButton>
            }
          </div>
          <div className="hidden md:block w-px border border-gray-light-1 border-opacity-[20%]" />
          {visitorMode ?
            <SuggestVerify /> :
            <div className="hidden md:block min-w-[50%]">
              {walletType == WalletType.MOVEMENT ? (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <img className="w-[133] justify-center mb-4" src="/credpoints/verify_suggestion_movement_general.svg" alt="effect1" />
                  <p className="text-[22px] font-bold leading-normal mb-4">Eligible activities are</p>
                  <button className="text-[22px] font-bold leading-normal text-[#F5E27D]">COMING SOON</button>
                </div>
              ) : walletType == WalletType.MONAD ? (
                <div className="flex items-center justify-center w-full h-full">
                  <img src="/credpoints/monad/item_third.svg" alt="" />
                </div>
              ) : (
                referralList.length > 0 ? (
                  <TokenList />
                ) : (
                  <div className="flex items-center justify-center w-full h-full">
                    <span className="text-sm text-center">
                      Last 10 rewards from holding tokens
                    </span>
                  </div>
                )
              )}
            </div>
          }
        </div>
      </div>
      <div className="block md:hidden">
        <div className={`${walletType == WalletType.MONAD ? 'h-[482px]' : 'h-[332px]'} md:h-[450px] container mt-4 flex border border-gray-light-2 rounded-xl`}>
          {walletType == WalletType.MONAD ? (
            <div className="min-w-full md:min-w-[50%] p-6 flex flex-col justify-center items-center gap-4">
              <img src="/credpoints/monad/mobile/token@2x.png" className="-mt-12" alt="" />
              <div className="-mt-5">
                <PointLogo />
              </div>
              <p className="px-8 text-2xl font-bold text-center">from holding or staking tokens</p>
              <div className="flex flex-col items-center w-full">
                <p className="text-sm text-center text-gray-light-3 w-[90%]">
                  Get daily points by holding or staking a certain amount of eligible tokens
                </p>
                {/* {walletType == WalletType.APTOS && <p onClick={() => dispatch(toggleEligibleTokenModal(true))} className="mt-2 text-sm font-normal cursor-pointer text-third-default">Show eligible tokens</p>} */}
              </div>
            </div>
          ) : (
            <div className="min-w-full md:min-w-[50%] p-6 flex flex-col justify-center items-center gap-4">
              <PointLogo />
              <p className="text-2xl font-bold text-center">from holding or staking tokens</p>
              <div className="flex flex-col items-center w-full">
                <p className="text-sm text-center text-gray-light-3 w-[90%]">
                  Get daily points by holding or staking a certain amount of eligible tokens
                </p>
                {walletType == WalletType.APTOS && <p onClick={() => dispatch(toggleEligibleTokenModal(true))} className="mt-2 text-sm font-normal cursor-pointer text-third-default">Show eligible tokens</p>}
              </div>
              {visitorMode ?
                <PrimaryButton
                  className="w-full text-sm md:hidden"
                  onClick={() => dispatch(toggleSuggestVerifyModal(true))}
                >
                  Verify account and join Cred
                </PrimaryButton> :
                <PrimaryButton
                  className="w-full py-4 text-sm md:hidden"
                  onClick={() => dispatch(toggleTokenModal(true))}
                >
                  Last 10 rewards
                </PrimaryButton>
              }
            </div>
          )}
          <div className="hidden md:block w-px border border-gray-light-1 border-opacity-[20%]" />
          {visitorMode ?
            <SuggestVerify /> :
            <div className="hidden md:block min-w-[50%]">
              {walletType == WalletType.MOVEMENT ? (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <img className="w-[133] justify-center mb-4" src="/credpoints/verify_suggestion_movement_general.svg" alt="effect1" />
                  <p className="text-[22px] font-bold leading-normal mb-4">Eligible activities are</p>
                  <button className="text-[22px] font-bold leading-normal text-[#F5E27D]">COMING SOON</button>
                </div>
              ) : walletType == WalletType.MONAD ? (
                <div className="flex items-center justify-center w-full h-full">
                  <img src="/credpoints/monad/item_third.svg" alt="" />
                </div>
              ) : (
                referralList.length > 0 ? (
                  <TokenList />
                ) : (
                  <div className="flex items-center justify-center w-full h-full">
                    <span className="text-sm text-center">
                      Last 10 rewards from holding tokens
                    </span>
                  </div>
                )
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default TokenBoard;
