import React from "react";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/primaryButton";

const FinalSeason = () => {
  const [backgroundImage, setBackgroundImage] = useState("/credpoints/bg-season-final.png");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setBackgroundImage("/credpoints/bg-mobile.png");
      } else {
        setBackgroundImage("/credpoints/bg-season-final.png");
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on current window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="w-full md:w-[1030px] h-[396px] sm:h-[240px] -mt-4 mb-[0px] px-4 md:px-0">
      <div
        className="container h-full relative w-full flex justify-center flex-col-reverse gap-10 md:flex-row items-center border border-[#f9bffeeb] rounded-xl"
        style={{
          backgroundImage: `url('${backgroundImage}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: "100% 100%"
        }}
      >
        <div className="flex flex-col items-center justify-center gap-4 px-4 md:px-12 md:mr-52">
          <img
            src="/credpoints/phone-final.svg"
            alt="mobile"
            className="block md:hidden w-[169px] left-auto right-auto -top-5  bottom-auto md:top-auto md:right-8 md:-bottom-0.5"
          />
          <div className="flex items-center justify-center">
            <img src="/credpoints/congrates.svg" className="mr-2 md:mr-4" />
            <p className="text-[22px] md:text-[26px] text-center font-bold">CRED Season 1 Finale Coming soon!</p>
            <img src="/credpoints/congrates.svg" className="ml-2 rotate-90 md:ml-4" />
          </div>
          <div className="flex flex-col md:flex-row items-center text-center text-[16px] md:text-[17px] font-bold">
            <div className="flex items-center">
            <p>Follow</p>
            <a href="https://twitter.com/townesquarexyz" target="_blank">
            <button className="flex items-center justify-center w-[191px] h-[37px] md:w-[215px] md:h-[44px] border-2 border-[#BB5EB3] rounded-[72px] bg-black bg-opacity-40 hover:bg-opacity-50 active:bg-opacity-60 mx-2">
                <img className="inline-block w-[21px] md:w-[23px] mr-2" src="/credpoints/ts-white-border.svg" alt="copy" />
                <p className=" text-[15px] md:text-[17px] font-semibold">@townesquarexyz</p>
              </button>
            </a>
            </div>
            <p>for the ultimate finale experience on mobile!</p>
          </div>
        </div>
        <img
          src="/credpoints/ts_phone@2x.png"
          alt="mobile"
          className="absolute md:block hidden w-[203px] left-auto right-auto -top-5  bottom-auto md:top-auto md:right-8 md:-bottom-0.5"
        />
      </div>
    </div>
  );
};

export default FinalSeason;
