import React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../components/header";
import InviteCode from "../../components/inviteCode";

import Banner from "./banner";
import Cards from "./cards";
import DefiActivity from "./defiActivity";
import MyTotal from "./myTotal";
import NftBoard from "./nftBoard";
import Referral from "./referral";
import "./index.css";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import {
  fetchCredpoints,
  updateConnection,
  updateCredPointsLive,
} from "../../state/credpoints";
import TokenBoard from "./tokenBoard";
import GameActivity from "./gameActivity";
import { updateRewardType, updateVisitorMode } from "../../state/global";
import SuggestVerifyNavbar from "../../components/header/suggestVerifyNavbar";
import Footer from "./footer";
import DeXActivity from "./dexActivity";
import NftTrades from "./nftTrades";
import SocialPayment from "./socialPayment";
import { WalletType } from "../../type/walletType";
import { useWallet } from "../../hook/useWallet";
import Joined from "./joined";
import ComingSoon from "./comingSoon";
import { RewardType } from "../../type/rewardType";
import FinalSeason from "./finalSeason";
import MonadBanner from "./monadbanner";
import { Wallet } from "ethers";

const CredPoints = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(
    (state) => state.profileState
  );
  const initialized = useAppSelector(state => state.profileState.initialized);
  const visitorMode = useAppSelector(state => state.globalState.visitorMode);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const rewardType = useAppSelector((state) => state.globalState.rewardType);
  const { connected, address, network } = useWallet();
  const isAptos = walletType == WalletType.APTOS;


  const onHandleRewardType = (type: RewardType) => {
    dispatch(updateRewardType(type));
  }
  const profileSuccessRef = useRef(profile.success);
  const visitorModeRef = useRef(visitorMode);

  useEffect(() => {
    profileSuccessRef.current = profile.success;
    visitorModeRef.current = visitorMode;
  }, [profile.success, address, visitorMode]);

  useEffect(() => {
    const fetchDataTimeout = setTimeout(() => {
      if (!profileSuccessRef.current && !visitorModeRef.current && walletType !== WalletType.MONAD) { // test monad
        navigate('/')
      }
    }, 5000);
    return () => {
      clearTimeout(fetchDataTimeout);
    };
  }, [profileSuccessRef, visitorModeRef]);

  useEffect(() => {
    if (!connected && walletType !== WalletType.MONAD) { // test monad
      navigate("/");
    }
  }, [connected, address])

  useEffect(() => {
    if (connected && address && initialized && !profile.success) {
      // dispatch(updateVisitorMode(true));
    }
  }, [address, initialized]);

  useEffect(() => {
    dispatch(updateConnection(connected));
    if (connected && address && initialized && profile.success) {
      dispatch(updateCredPointsLive(false));
      dispatch(fetchCredpoints({ wallet: address, inviteCode: profile.code, network }));
    }
  }, [connected, address, initialized, profile]);

  return (
    <div className="parallax" id="cred-point">
      <Header />
      <div className="parallax__group">
        <div className="parallax__layer cred__effect4 z-[100]">
          <img src="/credpoints/effect1.png" />
        </div>
        {walletType == WalletType.MONAD ? (
          <div className="parallax__layer cred__effect2">
            <img src="/credpoints/monad_back.svg" alt="effect4" />
          </div>
        ) : (
          <div className="parallax__layer cred__effect2">
            {isAptos && <img src="/credpoints/aptos_dots.png" className="cred_solana_dots" />}
            <img src={isAptos ? "/credpoints/aptos_effect.png" : "/credpoints/effect4.png"} alt="effect4" />
          </div>
        )}

        <div className="parallax__layer cred__effect3">
          <img src="/credpoints/effect2.png" alt="effect3" />
        </div>
        {walletType == WalletType.MONAD ? (
          <div className="parallax__layer cred__effect5">
            <img src="/credpoints/left_monad_back.svg" alt="effect5" />
          </div>
        ) : (
          <div className="parallax__layer cred__effect5">
            <img src={isAptos ? "/credpoints/effect5.png" : "/credpoints/movement_effect.png"} alt="effect5" />
          </div>
        )}
      </div>
      <SuggestVerifyNavbar />
      <div className="relative w-full flex justify-center z-10 !bg-fixed">
        <div className={`w-full md:w-[1000px] flex flex-col items-center ${visitorMode ? 'mt-[70px]' : 'mt-[116px]'} mb-10`}>
          {
            walletType === WalletType.MONAD ? (
              <>
                <MonadBanner />
                <MyTotal />
              </>
            ) : (
              <>
                <div className="block md:hidden">
                  <FinalSeason />
                </div>
                <InviteCode />
                <MyTotal />
                <Cards />
                <ComingSoon />
              </>
            )
          }
          {walletType == WalletType.MONAD ? (
            <>

              <DefiActivity />
              <NftBoard />
              <TokenBoard />
            </>
          ) : (
            <>
              <div className={`flex flex-row items-center justify-center w-[97%] h-12 md:my-10 mt-4 my-0 text-center`}>
                <button className={`w-1/2 text-primary-default text-sm ${rewardType ? 'border-b-2 border-b-gray-light-3' : 'border-b-4 border-b-primary-default'}  py-4 cursor-pointer`}
                  onClick={() => onHandleRewardType(RewardType.GENERAL)}
                >General rewards</button>
                <button className={`w-1/2 text-primary-default text-sm ${rewardType ? 'border-b-4 border-b-primary-default' : 'border-b-2 border-b-gray-light-3'} py-4 cursor-pointer`}
                  onClick={() => onHandleRewardType(RewardType.TOWNESQUARE)}
                >TowneSquare rewards</button>
              </div>

              {rewardType ? (
                <div>
                  <Joined />
                  <DeXActivity />
                  <NftTrades />
                  <SocialPayment />
                  <Banner />
                </div>
              ) :
                (
                  <div>
                    <DefiActivity />
                    <NftBoard />
                    <Referral />
                    <TokenBoard />
                    {walletType == WalletType.APTOS && <GameActivity />}
                    <Banner />
                  </div>
                )}
            </>
          )}

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CredPoints;
