import React from "react";
import PointLogo from "./pointLogo";
import "./index.css";
import PrimaryButton from "../../../components/primaryButton";
import NftList from "./nftList";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { toggleEligibleNftModal, toggleNftList, toggleSuggestVerifyModal } from "../../../state/dialog";
import SuggestVerify from "../../../components/suggestVerify";
import { WalletType } from "../../../type/walletType";

const NftBoard = () => {
  const dispatch = useAppDispatch();
  const nfts = useAppSelector((state) => state.credpointsState.nfts);
  const visitorMode = useAppSelector((state) => state.globalState.visitorMode);
  const walletType = useAppSelector((state) => state.globalState.walletType);

  return (
    <div className="w-full px-4">
      <div className="hidden md:block">
        <div className={`${walletType == WalletType.MONAD ? 'h-[482px]' : 'h-[332px]'} md:h-[450px] container mt-4 w-full flex border border-gray-light-2 rounded-xl`}>
          <div className="min-w-full md:min-w-[50%] px-6 py-8 flex flex-col justify-center items-center gap-4">
            <PointLogo />
            <p className="pt-2 text-2xl font-bold">from holding NFT</p>
            <p className="text-sm text-center text-gray-light-3">
              Get points daily by holding NFTs or staking them.
            </p>
            {walletType == WalletType.APTOS && <p onClick={() => dispatch(toggleEligibleNftModal(true))} className="pb-2 text-sm font-normal cursor-pointer text-third-default">Show eligible collections</p>}
            {visitorMode ? (
              <PrimaryButton
                className="w-full text-sm md:hidden"
                onClick={() => dispatch(toggleSuggestVerifyModal(true))}
              >
                Verify account and join Cred
              </PrimaryButton>
            ) : (
              <PrimaryButton
                className="w-full py-4 text-sm md:hidden"
                onClick={() => dispatch(toggleNftList(true))}
              >
                See eligible NFTs in your wallet
              </PrimaryButton>
            )}
          </div>
          <div className="hidden md:block w-px border border-gray-light-1 border-opacity-[20%]" />
          {visitorMode ? (
            <SuggestVerify />
          ) : (
            <div className="hidden md:block min-w-[50%]">
              {walletType == WalletType.MOVEMENT ? (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <img className="w-[133] justify-center mb-4" src="/credpoints/verify_suggestion_movement_general.svg" alt="effect1" />
                  <p className="text-[22px] font-bold leading-normal mb-4">Eligible activities are</p>
                  <button className="text-[22px] font-bold leading-normal text-[#F5E27D]">COMING SOON</button>
                </div>
              ) : walletType == WalletType.MONAD ? (
                <div className="flex items-center justify-center w-full h-full">
                  <img src="/credpoints/monad/item_sec.svg" alt="" />
                </div>
              ) : (
                nfts.length > 0 ? (
                  <NftList />
                ) : (
                  <div className="flex items-center justify-center w-full h-full">
                    <span className="text-sm text-center">
                      Here we’ll show the eligible
                      <br /> NFTs you have in the wallet
                    </span>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div className="block md:hidden">
        <div className={`${walletType == WalletType.MONAD ? 'h-[482px]' : 'h-[332px]'} md:h-[450px] container mt-4 w-full flex border border-gray-light-2 rounded-xl`}>
          {walletType == WalletType.MONAD ? (
            <div className="min-w-full md:min-w-[50%] px-6 py-8 flex flex-col justify-center items-center gap-4">
              <img src="/credpoints/monad/mobile/nft@2x.png" className="-mt-12" alt="" />
              <div className="-mt-5">
                <PointLogo />
              </div>
              <p className="pt-2 text-2xl font-bold">from holding NFT</p>
              <p className="px-6 text-sm text-center text-gray-light-3">
                Get points daily by holding NFTs or staking them.
              </p>
              {/* {walletType == WalletType.APTOS && <p onClick={() => dispatch(toggleEligibleNftModal(true))} className="pb-2 text-sm font-normal cursor-pointer text-third-default">Show eligible collections</p>} */}
            </div>
          ) : (
            <div className="min-w-full md:min-w-[50%] px-6 py-8 flex flex-col justify-center items-center gap-4">
              <PointLogo />
              <p className="pt-2 text-2xl font-bold">from holding NFT</p>
              <p className="text-sm text-center text-gray-light-3">
                Get points daily by holding NFTs or staking them.
              </p>
              {walletType == WalletType.APTOS && <p onClick={() => dispatch(toggleEligibleNftModal(true))} className="pb-2 text-sm font-normal cursor-pointer text-third-default">Show eligible collections</p>}
              {visitorMode ? (
                <PrimaryButton
                  className="w-full text-sm md:hidden"
                  onClick={() => dispatch(toggleSuggestVerifyModal(true))}
                >
                  Verify account and join Cred
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  className="w-full py-4 text-sm md:hidden"
                  onClick={() => dispatch(toggleNftList(true))}
                >
                  See eligible NFTs in your wallet
                </PrimaryButton>
              )}
            </div>
          )}
          <div className="hidden md:block w-px border border-gray-light-1 border-opacity-[20%]" />
          {visitorMode ? (
            <SuggestVerify />
          ) : (
            <div className="hidden md:block min-w-[50%]">
              {walletType == WalletType.MOVEMENT ? (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <img className="w-[133] justify-center mb-4" src="/credpoints/verify_suggestion_movement_general.svg" alt="effect1" />
                  <p className="text-[22px] font-bold leading-normal mb-4">Eligible activities are</p>
                  <button className="text-[22px] font-bold leading-normal text-[#F5E27D]">COMING SOON</button>
                </div>
              ) : walletType == WalletType.MONAD ? (
                <div className="flex items-center justify-center w-full h-full">
                  <img src="/credpoints/monad/item_sec.svg" alt="" />
                </div>
              ) : (
                nfts.length > 0 ? (
                  <NftList />
                ) : (
                  <div className="flex items-center justify-center w-full h-full">
                    <span className="text-sm text-center">
                      Here we’ll show the eligible
                      <br /> NFTs you have in the wallet
                    </span>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NftBoard;
