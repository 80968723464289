import React from "react";
import PrimaryButton from "../../../components/primaryButton";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import {
  toggleChangeUsername,
  toggleFirstVerifyModal,
  toggleUpdateUsername,
  toggleWalletPanel,
} from "../../../state/dialog";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { checkInviteCode, getProfile } from "../../../api/invite";
import { updateStep, updateWalletType } from "../../../state/global";
import PrivacyPolicy from "../../../components/privacyPolicy";
import Twitter from "../../../components/connectSocial/twitter";
import Discord from "../../../components/connectSocial/discord";
import { useWallet } from "../../../hook/useWallet";
import { fetchProfile } from "../../../state/profile";
import ChangeUsernameModal from "../../../components/modal/changeUsernameModal";
import ProfileMint from "../../../components/modal/profileMint";
import { WalletType } from "../../../type/walletType";

const Box = (boxVisible: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { connected, address, network } = useWallet();

  const initialized = useAppSelector((state) => state.profileState.initialized);
  const profile = useAppSelector((state) => state.profileState);

  const [otp, setOtp] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const step = useAppSelector((state) => state.globalState.step);

  const onInviteCode = async () => {
    if (!address) return;

    try {
      const inputCode = otp.toUpperCase();
      const res = await checkInviteCode(inputCode, address, network);
      if (res.success == false)
        setError("The invite code is not valid. Find one on socials!");
      else if (res.success == true) {
        setTimeout(() => {
          dispatch(fetchProfile({ wallet: address, network: network }));
        }, 1000);
      }
    } catch (e) {
      setError("The invite code is not valid. Find one on socials!");
    }
  };

  // useEffect(() => {
  //   if (step == 1) dispatch(toggleFirstVerifyModal(true));
  // }, [step]);

  useEffect(() => {
    const checkSignup = async () => {
      if (connected && address && initialized) {
        dispatch(toggleWalletPanel(false));

        if (profile.success && profile.userName) {
          navigate("/credPoints");
        } else if (!profile.success && !profile.newWallet) {
          dispatch(updateStep(2)); //
        } else {
          dispatch(toggleChangeUsername(true));
          dispatch(updateStep(1));
        }
      } else {
        dispatch(updateStep(0));
      }
    };
    checkSignup();
  }, [connected, initialized, profile.success]);

  const onConnectWallet = () => {
    if (!connected) dispatch(toggleWalletPanel(true));
  };

  return (
    <div
      className={`${boxVisible.boxVisible ? "block" : ""} ${step == 1 ? "md:flex" : "flex"
        } connect-button mt-16 md:mt-[15vh] flex-col items-center`}
    >
      <div
        className={`container connect-button mt-2 p-4 md:p-10 w-[95%] ${step == 1 || step == 3 ? "md:w-[770px] border-none" : "md:w-[550px]"
          } flex flex-col items-center border border-gray-light-2 rounded-xl`}
        style={step == 1 || step == 3 ? { background: "none" } : {}}
      >
        {step == 2 && (
          <>
            <p className="mt-4 text-base font-bold text-center md:text-xl">
              Insert an invite code
            </p>
            <p className="text-base text-center md:text-xl">
              to check out your Cred points!
            </p>
            <div className="mt-6">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={5}
                renderSeparator={<span className="w-3"></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle="otp-input"
              />
            </div>
            <div
              className={`${error ? "flex" : "hidden"
                } items-center justify-center h-4 mt-2`}
            >
              {error && (
                <p className="text-sm font-semibold text-[#DC3232]">{error}</p>
              )}
            </div>
            <PrimaryButton
              className="md:mt-6 w-[300px] z-[4] mb-6 py-4 px-8"
              onClick={() => {
                onInviteCode();
              }}
            >
              <span className="text-sm font-semibold leading-normal text-black md:text-base">
                Insert invite code
              </span>
            </PrimaryButton>
            <div className="hidden md:flex">
              <img
                src="/credpoints/icon-info.svg"
                className="w-[22px] h-[22px] mr-2"
                alt="cred"
              />
              <p className="text-[#FFF] text-base font-medium not-italic leading-[125%]">
                You and your inviter will earn CRED referral points only if both of you have minted CRED Profile NFT
              </p>
            </div>
          </>
        )}
        {step == 0 && (
          <div className="h-[201px] flex flex-col justify-center items-center md:px-4 px-12">
            <p className="md:mt-4 mt-0 text-base text-center font-Inter font-normal leading-[150%] md:text-xl md:px-0">
              Connect wallet to check out your Cred points!
            </p>
            <PrimaryButton
              className="mt-6 md:mt-8 z-[4] px-8 py-4"
              onClick={() => onConnectWallet()}
            >
              <span className="text-sm md:text-base">Connect Wallet</span>
            </PrimaryButton>
          </div>
        )}
        {step == 1 && (
          <>
            <div className="-mt-10 text-center md:-mt-32 md:grid">
              <p className="text-[34px] font-bold leading-[133%] mb-3">Hey fren, claim your CRED username!</p>
              <p className="text-lg font-normal font-Inter leading-[160%] mb-10">This is how you’ll be known across the TownSquare ecosystem!</p>
              <ChangeUsernameModal />
            </div>
          </>
        )}
        {step == 3 && (
          <>
            <ProfileMint />
          </>
        )}
      </div>
      <div className="flex mt-8">
        <button className="px-10 gap-2 py-4 flex justify-center items-center border border-[#F5E27D] rounded-full mt-4 mb-10"
        onClick={() => {dispatch(updateWalletType(WalletType.MONAD)); navigate('/credpoints')}}
        >
          <p className="font-Inter text-base font-semibold leading-normal text-[#F5E27D]">Continue to </p>
          <img src="/header/monad.svg" alt="" />
        </button>
      </div>
      <div className="mt-8">
        <div className="text-base text-center md:text-xl">Powering</div>
        <div className="flex items-center gap-5 mt-5">
          <img src="/home/aptos.svg" alt="aptos" className="h-4 md:h-6" />
          <img src="/home/movement.svg" alt="aptos" className="h-4 md:h-6" />
          <img src="/header/monad.svg" className="h-4 md:h-6" alt="" />
        </div>
      </div>
      <div className="mt-8">
        <PrivacyPolicy />
      </div>
    </div>
  );
};

export default Box;
