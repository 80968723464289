export const emailVerify = async (email: string, token: any) => {
    const url = `${process.env.REACT_APP_BACKEND}/activity/verifyEmail`;
    const strData = JSON.stringify({
        email,
        token
    });
    const res = await (
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: strData,
        })
    ).json();
    return res;
};

export const checkEmailVerifyCode = async (email: string, token: any, code: string) => {
    const url = `${process.env.REACT_APP_BACKEND}/activity/verifyEmailCode`;
    const strData = JSON.stringify({
        email,
        token,
        code
    });
    const res = await (
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: strData,
        })
    ).json();
    return res;
};

export const registerSocial = async (wallet: string, network: string, headerToken: any, socialType: string, avatar?: string) => {
    const url = `${process.env.REACT_APP_BACKEND}/activity/register_social`;
    const strData = JSON.stringify({
        headerToken,
        wallet,
        network,
        avatar,
        socialType
    });
    const res = await (
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: strData,
        })
    ).json();
    return res;
};

export const profileviewed = async (token: string) => {
    const url = `${process.env.REACT_APP_BACKEND}/activity/viewProfile`
    const strData = JSON.stringify({
        token,
    });
    const res = await (
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: strData,
        })
    ).json();
    return res;
};

export async function uploadProfileImage(data: FormData) {
    return fetch(`${process.env.REACT_APP_BACKEND}/activity/uploadPfp`, {
        method: "POST",
        body: data,
    })
        .then((resp) => resp.json())
        .catch((error) => {
            if (error.code == 400) console.log(error.message);
        });
}

export async function uploadAvatar(wallet: string, network: string, token: string, file: string) {
    const reqData = JSON.stringify({
        wallet,
        network,
        token,
        file
    })
    return fetch(`${process.env.REACT_APP_BACKEND}/activity/uploadAvatar`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: reqData,
    })
        .then((resp) => resp.json())
        .catch((error) => {
            if (error.code == 400) console.log(error.message);
        });
}

export async function removeProfileImage(token: string) {
    const url = `${process.env.REACT_APP_BACKEND}/activity/removePfp`
    const strData = JSON.stringify({
        token,
    });
    const res = await (
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: strData,
        })
    ).json();
    return res;
};

export async function setProfileName(name: string, token: string) {
    const url = `${process.env.REACT_APP_BACKEND}/activity/setProfileName`
    const strData = JSON.stringify({
        name,
        token
    });
    const res = await (
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: strData,
        })
    ).json();
    return res;
};

export async function claimUsername(name: string, token: string, isInit: boolean, file?: string) {
    const url = `${process.env.REACT_APP_BACKEND}/activity/claimUsername`
    const reqData = JSON.stringify({
        name,
        token,
        isInit,
        file
    });

    const response = await (
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: reqData,
        })
    ).json();
    return response;
}
