import React from "react";

const MonadBanner = () => {
  return (
    <>
    <div className="hidden w-screen py-4 mb-10 md:flex">
      <img src="/credpoints/monad/MonadCRED.svg" className="w-screen" alt="" />
    </div>
    <div className="flex w-screen mb-12 -mt-4 md:hidden">
      <img src="/credpoints/monad/mobile/main.svg" className="w-screen" alt="" />
    </div>
    </>
  );
};

export default MonadBanner;