import React from "react";
import { useAppSelector } from "../../../state/hooks";
import Share from "../../../components/share";
import { WalletType } from "../../../type/walletType";

const MyTotal = () => {
  const myPoint = useAppSelector((state) => state.credpointsState.totalPoint);
  const visitorMode = useAppSelector((state) => state.globalState.visitorMode);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const isAptos = walletType == WalletType.APTOS;

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-2xl font-bold md:text-3xl">
        {
          walletType === WalletType.APTOS && 'MY APTOS CRED POINTS' ||
          walletType === WalletType.MOVEMENT && 'MY MOVEMENT CRED POINTS' ||
          walletType === WalletType.MONAD && 'MY MONAD CRED POINTS'
        }
      </h1>
      <div className="flex flex-col items-center justify-center gap-4 mt-4 md:flex-row">
        <div className="container px-8 py-1 w-auto flex gap-2 justify-center items-center border-[3px] md:border-4 border-primary-default rounded-full">
          <p className="text-[38px] md:text-[57px] font-semibold">
            {walletType == WalletType.MONAD || visitorMode ? "???" : myPoint.toLocaleString()}
          </p>
          <img
            src="/credpoints/cred.svg"
            alt="cred"
            className="w-9 md:w-[54px]"
          />
        </div>
        {walletType !== WalletType.MONAD && (
          <p className="block w-full mt-1 text-sm text-center md:hidden md:text-base md:w-full">
            Rewards are recalculated instantly to <br /> reflect your current holdings.
          </p>
        )}
        <Share />
      </div>
      {walletType !== WalletType.MONAD && (
        <p className="hidden md:block mt-4 text-center text-sm md:text-base w-[90%] md:w-full">
          Reward points are calculated to reflect your current activities.
          <br />
          Some activities may take longer for the blockchain to update.
        </p>
      )}
    </div>
  );
};

export default MyTotal;
