import React, { useState } from "react";
import { QuestActivityType } from "..";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../state/hooks";
import { QuestsType } from "../../../../type/questsType";

interface Props {
  data: QuestActivityType;
}
const ClaimQuest: React.FC<Props> = ({ data }) => {
  const questsType = useAppSelector((state) => state.globalState.questsType);

  return (
    <div className="w-full px-4 ">
      <div className="md:h-[204px] w-full container mt-4 flex border border-gray-light-1 rounded-xl px-4 py-8 md:px-6 md:py-6">
        <div className="flex">
          <img src={data.imgSrc} className="md:w-[224px] md:h-full h-[74px] w-[74px]" alt="" />
        </div>
        <div className="flex flex-col justify-between md:w-[120%] w-[75%] ml-4">
          <div className="flex flex-col">
            <p className="mb-2 text-xl font-bold leading-normal font-Grotesk">{data.title}</p>
            <p className="text-sm font-normal leading-[20px] text-[#B9B9B9] font-inter">{data.content}</p>
          </div>
          <div className="hidden md:flex">
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-2">
                <img src="/quests/claimquest/clock.svg" alt="" />
                <p className="text-[15px] font-bold leading-normal text-[#B9B9B9]">
                  {questsType == QuestsType.ACTIVE ? 'Ends in' : ''}
                  {questsType == QuestsType.COMINGSOON ? 'Starts in' : ''}
                  {questsType == QuestsType.ENDED ? 'Ended' : ''}
                </p>
                <p className={`text-[15px] font-bold leading-normal ${data.days.includes('h') ? "text-[#F4428D]" : "text-[#FFFFFF]"}`}>{data.days}</p>
                <div className="flex w-[1px] h-[14px] border border-[#B9B9B9] ml-4"></div>
                <img src="/quests/claimquest/trophy.svg" className="ml-4" alt="" />
                <p className="text-[15px] font-bold leading-normal text-[#B9B9B9]">Reward</p>
                <p className="text-[15px] font-bold leading-normal text-[#FFFFFF]">{data.reward}</p>
                <img src="/credpoints/cred.svg" className="w-4 ml-1" alt="" />
              </div>
              <div className="flex items-start justify-center text-center">
                <Link to={"/quests/detail"} className="w-[150px] px-6 py-3 text-base text-center font-semibold leading-normal text-black rounded-full bg-primary-light hover:bg-primary-dark">
                  See details
                </Link>
              </div>
            </div>
          </div>
          <div className="flex md:hidden">
            <div className="flex flex-col items-start justify-between w-full gap-4">
              <div className="flex flex-col items-start w-full gap-2">
                <div className="flex w-full gap-2 mt-3">
                  <img src="/quests/claimquest/clock.svg" alt="" />
                  <p className="text-[15px] font-bold leading-normal text-[#B9B9B9]">
                    {questsType == QuestsType.ACTIVE ? 'Ends in' : ''}
                    {questsType == QuestsType.COMINGSOON ? 'Starts in' : ''}
                    {questsType == QuestsType.ENDED ? 'Ended' : ''}
                  </p>
                  <p className={`text-[15px] font-bold leading-normal ${data.days.includes('h') ? "text-[#F4428D]" : "text-[#FFFFFF]"}`}>{data.days}</p>
                </div>
                <div className="flex gap-2">
                <img src="/quests/claimquest/trophy.svg" className="" alt="" />
                <p className="text-[15px] font-bold leading-normal text-[#B9B9B9]">Reward</p>
                <p className="text-[15px] font-bold leading-normal text-[#FFFFFF]">{data.reward}</p>
                <img src="/credpoints/cred.svg" className="w-4" alt="" />
                </div>
              </div>
              <div className="flex items-start justify-center w-full px-4 mt-4 text-center">
                <Link to={"/quests/detail"} className="w-full px-6 py-3 text-base font-semibold leading-normal text-center text-black rounded-full bg-primary-light hover:bg-primary-dark">
                  See details
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimQuest;