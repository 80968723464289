import React from "react";
import Header from "../../components/header";
import { useAppSelector } from "../../state/hooks";
import { WalletType } from "../../type/walletType";
import Carousel from "./carousel";
import './index.css'
import Cards from "./cards";
import { updateQuestsType } from "../../state/global";
import { useDispatch } from "react-redux";
import { QuestsType } from "../../type/questsType";
import QuestActivities from "./questActivities";
import Footer from "../credPoints/footer";

const Quests = () => {
	const dispatch = useDispatch();
	const visitorMode = useAppSelector(state => state.globalState.visitorMode);
	const walletType = useAppSelector((state) => state.globalState.walletType);
	const questsType = useAppSelector((state) => state.globalState.questsType);

	const onHandleQuestsType = (type: QuestsType) => {
		dispatch(updateQuestsType(type));
	}
	const isAptos = walletType == WalletType.APTOS;
	return (
		<div className="parallax" id="cred-quests">
			<Header />
			<div className="parallax__group">
				<div className="parallax__layer cred__effect4 z-[100]">
					<img src="/credpoints/effect1.png" />
				</div>
				<div className="parallax__layer cred__effect2">
					{isAptos && <img src="/credpoints/aptos_dots.png" className="cred_solana_dots" />}
					<img src={isAptos ? "/credpoints/aptos_effect.png" : "/credpoints/effect4.png"} alt="effect4" />
				</div>
				<div className="parallax__layer cred__effect3">
					<img src="/credpoints/effect2.png" alt="effect3" />
				</div>
				<div className="parallax__layer cred__effect5">
					<img src={isAptos ? "/credpoints/effect5.png" : "/credpoints/movement_effect.png"} alt="effect5" />
				</div>
			</div>
			<div className="relative w-full flex justify-center z-10 !bg-fixed">
				<div className={`w-full md:w-[1000px] flex flex-col items-center ${visitorMode ? 'mt-[70px]' : 'mt-[116px]'} mb-10`}>
					<Carousel />
					<Cards />
					<div className="flex flex-row items-center justify-center w-[97%] h-12 md:my-10 mt-4 my-0 text-center">
						<button className={`w-1/2 text-primary-default text-sm ${questsType === QuestsType.ACTIVE ? 'border-b-4 border-b-primary-default' : 'border-b-2 border-b-gray-light-3'}  py-4 cursor-pointer`}
							onClick={() => onHandleQuestsType(QuestsType.ACTIVE)}
						>Active</button>
						<button className={`w-1/2 text-primary-default text-sm ${questsType === QuestsType.COMINGSOON ? 'border-b-4 border-b-primary-default' : 'border-b-2 border-b-gray-light-3'}  py-4 cursor-pointer`}
							onClick={() => onHandleQuestsType(QuestsType.COMINGSOON)}
						>Coming soon</button>
						<button className={`w-1/2 text-primary-default text-sm ${questsType === QuestsType.ENDED ? 'border-b-4 border-b-primary-default' : 'border-b-2 border-b-gray-light-3'} py-4 cursor-pointer`}
							onClick={() => onHandleQuestsType(QuestsType.ENDED)}
						>Ended</button>
					</div>
					<QuestActivities />
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Quests;