import React, { useEffect, useRef } from "react";
import { toggleChangeAvatarPanel, toggleSidebar, toggleWalletPanel } from "../../state/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import Menu from "./menu";
import { getBoringAvatar } from "../../util/boringAvatar";
import { reset } from "../../state/credpoints";
import { reset as resetLeaderboard } from "../../state/leaderboard";
import JoinUs from "./joinus";
import { useNavigate } from "react-router-dom";
import { WalletType } from "../../type/walletType";
import { useWallet } from "../../hook/useWallet";
import MenuType from "../../type/menuType";
import jwtEncode from 'jwt-encode';
import { SECRETKEY } from '../../constants';
import { uploadAvatar } from "../../api/profile";
import { updateProfileImage } from "../../state/profile";

const Sidebar = () => {
  const show = useAppSelector((state) => state.dialogState.bSidebar);
  const walletType = useAppSelector((state) => state.globalState.walletType);
  const avatar = useAppSelector((state) => state.profileState.avatar);
  const profile = useAppSelector(state => state.profileState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { connected, address, disconnect, network } = useWallet();

  const onDisconnet = () => {
    dispatch(reset(true));
    dispatch(resetLeaderboard(true));
    disconnect();
  }
  const inviteCode = useAppSelector((state) => state.credpointsState.initInviteCode);
  const profileViewed = useAppSelector((state) => state.profileState.profileViewed);
  const userName = useAppSelector((state) => state.profileState.userName);

  const ref = useRef<any>(null);;
  useEffect(() => {
    if (profile.avatar === "" && connected && address) {
      const ondrawImage = async () => {
        if (!ref.current) return;
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.src = ref.current.src;
        await new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
        });
        console.log(image);
        const canvas = document.createElement("canvas");
        canvas.width = 40;
        canvas.height = 40;
        const ctx = canvas.getContext("2d");
        ctx?.clearRect(0, 0, canvas.width, canvas.height);
        ctx?.drawImage(image, 0, 0, 40, 40);
        var jpegUrl = canvas.toDataURL("image/png");
        console.log("JPEGURL", jpegUrl);
        const token = jwtEncode({ wallet: address }, SECRETKEY);
        if (address && ref.current) {
          const res = await uploadAvatar(address, network, token, jpegUrl);
          if (res) {
            console.log(res);
            dispatch(updateProfileImage(res.url))
            dispatch(toggleChangeAvatarPanel(false))
          }
        }
      }
      ondrawImage().catch(error => console.error("Image loading failed:", error));
    }
  }, [profile])

  return (
    <div
      className={`${show ? "block" : "hidden"
        } fixed w-full h-screen inset-0 bg-gray-dark-1 z-50`}
      onClick={() => dispatch(toggleSidebar(false))}
    >
      <div
        className="fixed w-2/3 h-screen top-0 right-0 z-50 flex  flex-col items-center justify-between gap-4  bg-[#2A2A2A]"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(toggleSidebar(false));
        }}
      >
        <div className="h-10 mt-10">
          {connected && (
            <div className="flex items-center gap-2">
              <div className="w-8 h-8">
                <img
                  src={profile.avatar == "" ? getBoringAvatar(address) : avatar}
                  crossOrigin={profile.avatar === "" ? 'anonymous' : undefined}
                  ref={ref}
                  className="w-8 h-8 rounded-full"
                  alt="ellipse"
                />
              </div>
              <span className="hover:font-bold">
                {address?.slice(0, 5)}...{address?.slice(-3)}
              </span>
            </div>
          )}
        </div>
        {connected && userName !== null && (
          <div className="flex flex-col items-center gap-8">
            {Menus.map((menu, index) => (
              <Menu data={menu} key={index} />
            ))}
            {inviteCode && <div className="flex">
              <p className="flex items-center cursor-pointer text-gray-light-3 hover:font-bold whitespace-nowrap" onClick={() => navigate('/profile')}>Profile</p>
              {!profileViewed && <img src="/credpoints/icon-warning.svg" className="w-[24px] h-[24px] ml-2" alt="cred" />}
            </div>}
          </div>
        )}
        <div className="absolute bottom-[100px]">
          <div className="flex flex-col items-center gap-6">
            <p className="text-gray-light-3">Follow us</p>
            <JoinUs />
          </div>
        </div>
        <div className="flex justify-center w-full py-4 border-t border-gray-light-1">
          {connected ? (
            <p
              className="font-semibold text-gray-light-5 hover:text-white whitespace-nowrap"
              onClick={() => onDisconnet()}
            >
              Disconnect Wallet
            </p>
          ) : (
            <p
              className="font-semibold text-gray-light-5 hover:text-white whitespace-nowrap"
              onClick={() => dispatch(toggleWalletPanel(true))}
            >
              Connect Wallet
            </p>
          )}
        </div>
      </div>
    </div>
  );
};


const Menus: MenuType[] = [
  {
    href: "/credPoints",
    label: "Cred points",
    color: "#F5E27D",
  },
  // {
  //   href: "/quests",
  //   label: "Quests",
  //   color: "#F5E27D",
  // },
  {
    href: "/leaderboard",
    label: "Leaderboard",
    color: "#A3A1E2",
  },
  {
    href: "/profile",
    label: "Profile",
    color: "#A3A1E2",
  },
  {
    href: "/about",
    label: "About",
    color: "#45A9A7",
  },
];

export default Sidebar;
