import React from "react";
import { useAppSelector } from "../state/hooks";
import { useState } from "react";
import { toast } from "react-toastify";
import Tooltip from "./tooltip";
import { WalletType } from "../type/walletType";

const InviteCode = () => {
  const inviteCode = useAppSelector(
    (state) => state.credpointsState.inviteCode
  );
  const [isCopied, setIsCopied] = useState(false);
  const walletType = useAppSelector((state) => state.globalState.walletType);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(inviteCode ?? "");
      setIsCopied(true);
      toast.success("Invite code has been copied", {
        className: "custom-toast",
        // bodyClassName: 'custom-toast-body',
        // progressClassName: 'custom-toast-progress',
      });
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    }
  };
  return (
    <>
      {inviteCode && (
        <div className="px-4 pt-8 md:px-0">
          <div className="grid bg-[#191919] p-4 md:px-0 w-full md:w-[1030px] min-h-[80px] mb-8 md:mb-[71px] justify-center items-center border border-gray-light-7 rounded-2xl">
            <div className="grid items-center justify-center gap-2 md:flex me-4">
              <p className="text-base font-normal md:text-xl">
                Get <b>50</b>{" "}
                <img
                  className="w-[24px] inline-block"
                  src={"/credpoints/cred.svg"}
                  alt="swap"
                />{" "}
                for every fren that joins CRED using your{" "}
                <img src={`${walletType == WalletType.APTOS ? '/home/aptos.svg' : '/home/movement.svg'}`} className={`${walletType == WalletType.APTOS ? 'w-[20%] md:w-[12%] mb-1' : 'w-[30%] md:w-[17%] mb-1'} inline-block`} alt="" />
                {" invite code:"}
              </p>
              <div className="flex items-center justify-center">
                <p
                  className="w-[127px] flex justify-center items-center text-[22px] md:text-[26px] font-normal cursor-pointer hover:bg-[#ffffff1a] rounded-[50px] px-6 py-1"
                  onClick={handleCopyToClipboard}
                >
                  {inviteCode}&nbsp;
                  <img
                    className="inline-block w-[24px]"
                    src="/copy.svg"
                    alt="copy"
                  />
                </p>

                <Tooltip
                  className="w-[300px] text-wrap text-center"
                  label={
                    <>
                      You and your invited fren will only earn referral points if your fren’s address is an active account on {walletType == WalletType.APTOS ? 'Aptos' : 'Movement'}
                    </>
                  }
                >
                  <img
                    src={"/credpoints/question_tip.svg"}
                    className="w-6 h-6"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InviteCode;
